.main-backend-content-opticontrol {
  margin-top: 20px;
  .watch-dog-table {
    .ant-table-body {
      display: none;
    }
  }
  .for-pdf-page {
    display: block !important;
    .ant-table-header {
      display: none;
    }
  }
  .ant-table-expanded-row {
    background-color: #f8f9fb;
    .ant-table-tbody {
      td {
        background-color: #ffffff;
      }
    }
  }
  .over-view-button {
    display: none;
  }
  .main-row-header {
    padding-bottom: 25px;
    align-items: center;

    .header-top-right {
      display: none;
      column-gap: 10px;
      justify-content: flex-end;
    }
  }
  .ant-picker-range {
    width: 100%;
  }
  .filter-top-opticontrol .ant-select {
    background: #12d6a2;
    border-radius: 5px;
    color: #e2f7f2;
    font-weight: 600;
    width: 100%;
    .anticon {
      color: #e2f7f2;
    }
    .ant-select-selector {
      border: none;
      align-items: center;
    }
    .ant-select-selection-item {
      background: none !important;
    }
    .ant-select-selection-placeholder {
      color: #e2f7f2;
    }
    .ant-select-multiple .ant-select-selection-item-content {
      margin-right: 8px;
    }
    .ant-select-arrow {
      top: 20px;
    }
  }
  .filter-top-opticontrol .ant-cascader-picker {
    background: #12d6a2;
    border-radius: 5px;
    color: #e2f7f2;
    font-weight: 600;
    .anticon {
      color: #e2f7f2;
    }
    .ant-select-selector {
      border: none;
      align-items: center;
    }
  }
  .filter-top-opticontrol .ant-picker {
    height: 38px;
    min-width: 120px;
    width: 100%;
    border: 1px solid #12d6a2;
    border-color: #12d6a2;
    // box-shadow:none;
    .ant-picker-suffix {
      color: #12d6a2;
    }
    input {
      color: #12d6a2;
      font-weight: 600;
    }
  }
  .ant-typography {
    margin-bottom: 0em !important;
  }
  h5 {
    font-size: 30px;
  }
  .ant-row-space-between {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}
// main {display: flex;}
.main-backend-content-opticontrol .main > * {
  border: 1px solid;
}
.main-backend-content-opticontrol .table {
  border-collapse: collapse;
  font-family: helvetica;
}

.main-backend-content-opticontrol {
  td,
  th {
    padding: 16px;
    min-width: 145px;
    font-weight: 500;
    box-sizing: border-box;
    text-align: center;
  }
}
.main-backend-content-opticontrol {
  position: relative;
  width: 1500px;
  .ant-table-cell-scrollbar {
    display: none !important;
  }
  .align-center-v {
    input {
      padding: 12px 5px !important;
    }
  }
  .ant-checkbox-wrapper {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #272b41;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    display: inline-flex;
    align-items: baseline;
    line-height: unset;
    cursor: pointer;
  }
  .ant-checkbox {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #272b41;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    top: 0.2em;
    line-height: 1;
    white-space: nowrap;
    outline: none;
    cursor: pointer;
  }
  .ant-checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
  .filters-row {
    margin-bottom: 15px;
  }
  .ant-checkbox + span {
    padding-right: 8px;
    padding-left: 8px;
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #00ca95;
      border-color: #00ca95;
    }
  }
  .ant-checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    direction: ltr;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    border-collapse: separate;
    transition: all 0.3s;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }
  .ant-checkbox-inner::after {
    position: absolute;
    top: 50%;
    left: 21.5%;
    display: table;
    width: 5.71428571px;
    height: 9.14285714px;
    border: 2px solid #fff;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(0) translate(-50%, -50%);
    opacity: 0;
    transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
    content: ' ';
  }
  .ant-checkbox-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #00ca95;
    border-radius: 4px;
    visibility: hidden;
    -webkit-animation: antCheckboxEffect 0.36s ease-in-out;
    animation: antCheckboxEffect 0.36s ease-in-out;
    -webkit-animation-fill-mode: backwards;
    animation-fill-mode: backwards;
    content: '';
  }
  .ant-checkbox-wrapper::after {
    display: inline-block;
    width: 0;
    overflow: hidden;
    content: '\a0';
  }
}

.main-backend-content-opticontrol .tfoot {
  position: -webkit-sticky;
  bottom: 0;
  z-index: 2;
}

.main-backend-content-opticontrol tfoot td {
  position: sticky;
  bottom: 0;
  z-index: 2;
  background: hsl(20, 50%, 70%);
}

.main-backend-content-opticontrol {
  .ant-table-body {
    overflow: unset !important;
    max-height: 100% !important;
    table {
      width: 100% !important;
      table-layout: unset !important;
    }
  }
  .hide-in-pdf {
    display: none !important;
  }
  .ant-table-header {
    overflow: unset !important;
    table {
      table-layout: unset !important;
    }
  }
  .ant-table-thead {
    background: rgb(248, 249, 251);
    border: 1px solid rgb(241, 242, 246);
  }
}
